import React from 'react'
import { RadioGroup } from '@material-ui/core'
import clsx from 'clsx'
import { FormHelperText } from '@objects/formfields/index'

export type RadioGroupFieldProps = RvG.IReactDefaultProps & {
  error?: string
  touched?: boolean | false
}

export default function RadioGroupField({
  touched,
  error,
  children,
  ...props
}: RadioGroupFieldProps) {
  const classNames = clsx({ 'has-error': touched && !!error })
  return (
    <RadioGroup className={classNames} {...props}>
      {children}
      {/*{error && <FormHelperText>{error}</FormHelperText>}*/}
    </RadioGroup>
  )
}
