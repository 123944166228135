import React, { ReactElement, useEffect, useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'
import Headline from '@objects/headline'
import BreadCrumbs from '@objects/breadcrumb'
import { Grid, RadioGroup, Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import {
  Checkbox,
  CheckboxField,
  FormHelperText,
  PostInputField,
  Radio,
  RadioField,
  RichTextEditor,
  Select,
  SelectInline,
  TextAreaField,
  TextField,
  TextInput,
} from '@objects/formfields'
import { Formik, Form, FormikHelpers, Field } from 'formik'
import * as Yup from 'yup'
import Button from '@objects/button'
import clsx from 'clsx'
import RadioGroupField from '@objects/formfields/radioGroupField'

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    pageRoot: {
      marginTop: theme.spacing(15),
    },
    grid: {
      marginBottom: theme.spacing(15),
    },
  })
)

export default function RootIndex(): ReactElement {
  const classes = useStyles()
  const formTest = useRef(null)

  const validationSchema = Yup.object({
    selectInlineError: Yup.string().required('contact.name.error.required'),
    checkboxError: Yup.boolean().oneOf([true], 'error').required('error empty'),
    testFormRadio: Yup.string(),
    testErrorFormRadio: Yup.string()
      .oneOf(['4'], 'error')
      .required('error empty'),
    TextFieldDisabled: Yup.string(),
    TextFieldActive: Yup.string(),
    TextFieldActiveError: Yup.string().required(),
    TextFieldActiveFilled: Yup.string().required(),
    TextFieldActiveFilledError: Yup.string()
      .required() /*.matches(/^(TEST)$/, 'no match')*/
      .min(55, 'too short'),
    textAreaField: Yup.string(),
  })

  type TestFormValues = {
    selectInlineError: string
    checkboxError: boolean
    testFormRadio: string
    testErrorFormRadio: string
    TextFieldDisabled: string
    TextFieldActive: string
    TextFieldActiveError: string
    TextFieldActiveFilled: string
    TextFieldActiveFilledError: string
    textAreaField: string
  }
  const initialValues: TestFormValues = {
    selectInlineError: '',
    checkboxError: false,
    testFormRadio: '',
    testErrorFormRadio: '',
    TextFieldDisabled: '',
    TextFieldActive: '',
    TextFieldActiveError: '',
    TextFieldActiveFilled: 'testValue',
    TextFieldActiveFilledError: 'testValueError',
    textAreaField: '',
  }

  function _handleSubmit(
    values: TestFormValues,
    { resetForm, setSubmitting }: FormikHelpers<TestFormValues>
  ): void {
    try {
      validationSchema.validate(values)
    } catch (e) {}
  }

  useEffect(() => {
    document?.querySelector('#formTestSubmit')?.click()
  }, [])

  return (
    <>
      <BreadCrumbs
        crumbList={[{ label: 'Test', link: '/' }, { label: 'Current Page' }]}
      />
      <Container type="article" className={classes.pageRoot}>
        <Headline level={1}>Examples</Headline>
        <Headline level={2}>Form Fields</Headline>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
          validateOnBlur={true}
        >
          {({ isSubmitting, errors, touched, values, ...args }) => (
            <Form id={'testForm'}>
              {/* Radio */}
              <div style={{ width: '50%', float: 'left' }}>
                <RadioGroupField id={'testFormRadio'} defaultValue={'3'}>
                  <Field
                    component={RadioField}
                    value={'1'}
                    label={'inactive'}
                    id={'radioInactive'}
                    name={'testFormRadio'}
                    disabled={true}
                  />
                  <Field
                    component={RadioField}
                    value={'2'}
                    label={'unchecked'}
                    id={'radioUnchecked'}
                    name={'testFormRadio'}
                  />
                  <Field
                    component={RadioField}
                    value={'3'}
                    label={'checked'}
                    id={'radioChecked'}
                    name={'testFormRadio'}
                  />
                </RadioGroupField>

                <RadioGroupField
                  id={'testErrorFormRadio'}
                  error={errors.testErrorFormRadio}
                  touched={touched.testErrorFormRadio}
                >
                  {/*Data: { data }*/}
                  <Field
                    component={RadioField}
                    value={'4'}
                    label={'hasError'}
                    name={'testErrorFormRadio'}
                    id={'testErrorFormRadio4'}
                  />
                </RadioGroupField>
              </div>
              {/* Checkboxes */}
              <div style={{ width: '50%', float: 'left' }}>
                <CheckboxField
                  label={'inactive'}
                  name={'checkboxInactive'}
                  disabled={true}
                />
                <br />
                <CheckboxField
                  label={'unchecked'}
                  name={'checkboxUnchecked'}
                  checked={false}
                />
                <br />
                <CheckboxField
                  label={'checked'}
                  name={'checkboxChecked'}
                  checked={true}
                />
                <br />
                <CheckboxField
                  label={'error'}
                  name={'checkboxError'}
                  error={'this is an error'}
                  value={'1'}
                />
              </div>

              {/* TextFields */}

              <div>
                <TextField
                  type={'text'}
                  name={'TextFieldDisabled'}
                  id={'Disabled'}
                  label={'Text Field Disabled'}
                  disabled={true}
                />
                <br />
                <TextField
                  type={'text'}
                  name={'TextFieldActive'}
                  id={'Active'}
                  label={'Text Field Active'}
                />
                <br />
                <TextField
                  type={'text'}
                  name={'TextFieldActiveError'}
                  id={'ActiveError'}
                  label={'Text Field Active Error'}
                  error={true}
                />
                <br />
                <TextField
                  type={'text'}
                  name={'TextFieldActiveFilled'}
                  id={'ActiveFilled'}
                  label={'Text Field Active'}
                />
                <br />
                <TextField
                  type={'text'}
                  name={'TextFieldActiveFilledError'}
                  id={'ActiveFilledError'}
                  label={'Text Field Filled Error'}
                />
              </div>
              {/* select */}
              <div>
                <SelectInline
                  name={'selectInline'}
                  settings={{
                    placeholder: 'test select inline Label',
                    label: 'test select inline',
                    name: 'testSelect',
                    options: [
                      { label: 'Select...', value: false },
                      { label: 'first', value: 'first' },
                      { label: 'second', value: 'second' },
                    ],
                  }}
                />
                <SelectInline
                  name={'selectInlineError'}
                  settings={{
                    label: 'test select inline',
                    name: 'testSelect',
                    options: [{ label: '1', value: '1' }],
                  }}
                />
              </div>

              <div>
                <TextAreaField
                  name={'textAreaField'}
                  label={'Test Text Area'}
                  rowsMin={4}
                />
              </div>
              <Button
                type="main"
                disabled={isSubmitting}
                icon="TextArrow"
                formSubmit
                id={'formTestSubmit'}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}
